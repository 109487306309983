import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import Button from 'react-bootstrap/Button'
import Http from 'axios'
import Card from 'react-bootstrap/Card'
import Dashboard from './NewDashboard'
import NewLogin from '../Auth/NewLogin'

class UnscheduledExamDashboard extends Component {

  constructor() {
    super();
    this.state =
    {
      isLogged: false,
      isLoaded: false,
      exams: [],
      users: [],
      id: 0,
      found: false,
      showDate: false,
      userMenu: false,
      menu: false,
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleUserMenu = this.toggleUserMenu.bind(this);
  }
openfullscreen(elem) {
	    if(elem.requestFullscreen)
		    elem.requestFullscreen();
	    else if(elem.webkitRequestFullscreen)
		    elem.webkitRequestFullscreen();
	    else if(elem.msRequestFullscreen)
		    elem.msRequestFullscreen();
}
  getExamStatus = (exam) => {
    const examStartTime = Date.parse("1970-01-01T" + exam.start_time.toString() + "Z");
    const examEndTime = examStartTime + exam.duration * 60000;
    const now = Date.now();

    if (Date.parse(exam.date) + examStartTime < now) {
      if (examEndTime > now) {
        return "Start Now";
      } else {
        return "Done";
      }
    } else {
      return "Not Started";
    }
  }
  gotoProfile = (id, exam) => {
    //localStorage.setItem('exam',JSON.stringify(this.state.exams[id-1]));
  }
  doesUserExist = (user, email) => {
    //matching the user and the invigilator
    if (email == undefined) {
      console.log("Email not defined");
      return false;
    }
    if ((user.email == email) && (user.invig_id == this.state.user.id)) {
      this.state.found = true;
      console.log("user found")
      this.state.email = email
      this.state.user_id = user.id
      return true;
    }
    else {
      //console.log("user does not exist")
      const errorMsgElement = document.querySelector('span#errorMsg');
     // errorMsgElement.innerHTML = "User not attached to Invigilator. Please invite user";
      return false;
    }
  }
  handleChange = (event) => {
    let nam = event.target.name;
    let val = event.target.value;
    this.setState({ id: 0 });
    //TODO: return false if user doesn't exist and send an email. If exists send id to create a map entry
    //email to user_id conversion
    /*if(nam =='user_id')
    //this.state.users.filter(user=>((user.email==val)&&(user.invig_id==this.state.user.id))).map(user=>(this.setState({user_id:user.id})));
    this.state.users.filter(user=>(this.doesUserExist(user,val))).map(user=>(this.setState({user_id:user.id})));
    else*/
    this.setState({ [nam]: val });
    //if(!this.state.found)
    //	console.log("Failed to find user");


  }
  //Add Student
  handleClick = (exam) => {
    localStorage.setItem('exam1', JSON.stringify(exam));
    var status;
    const errorMsgElement = document.querySelector('span#errorMsg');

    if (exam.date !== undefined) { //Scheduled Exam
      //status=(Date.parse(exam.date)+Date.parse("1970-01-01T"+exam.start_time.toString()+"Z"))<(Date.now())?(((Date.parse(exam.date)+Date.parse("1970-01-01T"+exam.start_time.toString()+"Z")+exam.duration*60000)>(Date.now()))?"Ongoing":"Done"):"Not Started";
      status = ((Date.parse(exam.date) + Date.parse("1970-01-01T" + exam.start_time.toString() + "Z")) < (Date.now())) ? (((Date.parse(exam.date) + Date.parse("1970-01-01T" + exam.start_time.toString() + "Z") + exam.duration * 60000) > (Date.now())) ? "Ongoing" : "Late") : "Not Started";
      console.log(status);
      if (status == 'Ongoing') //Ongoing Exam
      {
        console.log("Too late to add a student");
      }
      else if (status == "Not Started") { //Upcoming Exam
        console.log("Adding student to exam");
        this.state.users.filter(user => (this.doesUserExist(user, this.state.user_id))).map(user => (this.setState({ user_id: user.id })));
        if (this.state.email) {
          var api = "exam-api/exame/" + this.state.user_id + "/" + exam.id + "/" + this.state.email + "/" + this.state.user.email;
          //TODO Add function to  invite user here if he does not exist
          Http.post(api)
            .then(res => {
              console.log(res);
              errorMsgElement.innerHTML = this.state.email + " added";
            })
            .catch(function (error) {
              console.log(error);
              errorMsgElement.innerHTML = "Could not add " + this.state.email;
            });
        }
        else {
          console.log("Failed to find user");
          errorMsgElement.innerHTML = "Could not find " + this.state.user_id + ". Invite user from Student Dashboard";
        }
      }
      else //Past Exam
      {
        console.log("Exam has already occured.");
      }
    }
    else //unscheduled exam
    {
      this.state.users.filter(user => (this.doesUserExist(user, this.state.user_id))).map(user => (this.setState({ user_id: user.id })));
      if (this.state.email) {
        var api = "exam-api/exame/" + this.state.user_id + "/" + exam.id + "/" + this.state.email + "/" + this.state.user.email;
        //TODO Add function to  invite user here
        Http.post(api)
          .then(res => {
            console.log(res);
            errorMsgElement.innerHTML = "Added " + this.state.email;
          })
          .catch(function (error) {
            console.log(error);
            errorMsgElement.innerHTML = "Could not add " + this.state.email;
          });
      }
      else {
        console.log("Failed to find user");
      }

    }
  }

  startExam = (exam) => {
    const errorMsgElement = document.querySelector('span#errorMsg');
    //update start time and end time
    //save exam to the local storage
    //var x=new Date();
    //exam.date=x.getFullYear()+"-"+(x.getMonth()+1)+"-"+x.getDate();
    //exam.start_time =x.getHours()+":"+x.getMinutes();
    if (exam.status != "Completed" || exam.status == null) {
      var x = new Date().toISOString();
      exam.date = x.slice(0, 10);
      exam.start_time = x.slice(11, 19);
      exam.status = "Ongoing";
      localStorage.setItem('exam1', JSON.stringify(exam));
      localStorage.setItem('user', JSON.stringify(this.state.user));
      //this.getAssertion();
      if (this.state.user.role != "invig") { //Student schedules Exam
        Http.put('exam-api/examt/' +
          this.state.user.id + '/' +
          exam.id + '/' +
          exam.date + '/' +
          exam.start_time, null, {
          headers: {
            'Content-Type': 'application/json',
            // Add any other headers if required for your API, such as authorization headers
          }
        })
          .then(res => {
            console.log("Exam Started");
            errorMsgElement.classList.remove("badge-outline-info");
            errorMsgElement.classList.add("badge-outline-success");
            errorMsgElement.innerHTML = "Exam Started";
            for (let i = 0; i < this.state.exams.length; i++) {
              const updatedExams = [...this.state.exams];
              if (updatedExams[i].id == exam.id) {
                updatedExams[i].date = exam.date;
                updatedExams[i].start_time = exam.start_time;
                this.setState({ exams: updatedExams });
                break;
              }
            }
            return exam;
          })
          .catch(function (error) {
            errorMsgElement.classList.remove("badge-outline-success");
            errorMsgElement.classList.add("badge-outline-warning");
            errorMsgElement.innerHTML = error;
            console.log(error);
          });
      }
      this.state.link = "/verifyhelp";
    }
    else if (exam.status == "Ongoing") {
      var x = new Date().toISOString();
      exam.date = x.slice(0, 10);
      exam.start_time = x.slice(11, 19);
      exam.status = "Ongoing";
      this.getAssertion();
      exam.duration = exam.duration - exam.attendance;
      if (exam.duration > 0) {
        localStorage.setItem('exam1', JSON.stringify(exam));
        localStorage.setItem('user', JSON.stringify(this.state.user));
        this.state.link = "/verifyhelp";
      }
      else { //submit the exam after time over
        this.state.link = "#";
        exam.status = "Completed";
        var api = "exam-api/exams/" + this.state.user.id + "/" + this.state.exam.id;
        Http.put(api)
          .then(response => { alert("Time Over"); });

      }
    }
    else {
      this.state.link = "#";
      alert("You have already submitted your exam");
    }
    return exam;
  }
  viewExam = () => {
    console.log("View Exam");
  }

  //schedule an exam for unscheduled exams
  scheduleExam = (exam) => {
    const errorMsgElement = document.querySelector('span#errorMsg');
    localStorage.setItem('exam1', JSON.stringify(exam));

    if (exam.date === undefined) {
      console.log("Exam Schedule routine clicked");
      this.setState({ showDate: true });
      var x = new Date(Date.parse(this.state.date) + Date.parse("1970-01-01T" + this.state.start_time + "Z"));
      var x = new Date(Date.parse(this.state.date + "T" + this.state.start_time + "Z"));
      var y = new Date(x.getTime() + x.getTimezoneOffset() * 60000);
      this.state.date = y.toISOString();
      let time = this.state.date.split("T");
      this.state.date = time[0];
      time = time[1].split(".");
      this.state.start_time = time[0];
      exam.date = this.state.date;
      exam.start_time = this.state.start_time;
      for (let i = 0; i < this.state.exams.length; i++) {
        const updatedExams = [...this.state.exams];
        if (updatedExams[i].id == exam.id) {
          updatedExams[i].date = exam.date;
          updatedExams[i].start_time = exam.start_time;
          this.setState({ exams: updatedExams });
          break;
        }
      }

      if (this.state.user.role != "invig") { //Student schedules Exam
        Http.put('exam-api/examt/' +
          this.state.user.id + '/' +
          exam.id + '/' +
          this.state.date + '/' +
          this.state.start_time, null, {
          headers: {
            'Content-Type': 'application/json',
            // Add any other headers if required for your API, such as authorization headers
          }
        })
          .then(res => {
            console.log("Exam Scheduled");
            errorMsgElement.classList.remove("badge-outline-info");
            errorMsgElement.classList.add("badge-outline-success");
            errorMsgElement.innerHTML = "Exam Scheduled";
            return exam;
          })
          .catch(function (error) {
            errorMsgElement.innerHTML = error;
            console.log(error);
          });
      }
      else { //Proctor Schedules Exam
        Http.put('exam-api/exam/' + exam.id, {
          "date": this.state.date,
          "start_time": this.state.start_time,
        }, {
          headers: {
            "Content-Type": "application/json"
          }
        })
          .then(res => {
            console.log("Exam scheduled Successfully");
            errorMsgElement.classList.remove("badge-outline-info");
            errorMsgElement.classList.add("badge-outline-success");
            errorMsgElement.innerHTML = "Exam scheduled successfully";
          })
          .catch(function (error) {
            errorMsgElement.innerHTML = error;
            console.log(error);
          });
      }
    }
    else {
      console.log("Exam already scheduled at ", exam.date, exam.start_time);
      errorMsgElement.innerHTML = "Exam already scheduled at " + exam.date + " " + exam.start_time;
    }
  }
  async componentWillMount() {
    const user = JSON.parse(localStorage.getItem('user'));
    var tout, minutes_left;
    if (this.state.user = user)
      this.state.isLogged = true;
    else {
      console.log("User not Logged in");
      if (this.props.user) {
        this.state.user = this.props.user;
        this.state.isLogged = true;
      }
      else
        this.state.isLogged = false;
    }
    //for invigilators and 
    //TODO: admin
    if (this.state.user.role == 'invig') {
      var api = "exam-api/exam";
    }
    else {
      var api = "exam-api/examt/" + this.state.user.id;
      if (this.state.user)
        localStorage.setItem('user', JSON.stringify(this.state.user));
    }
    const response = await Http.get(api);
    /*	.then(res => {
                    const response = res.data;
                    this.setState({ isLoaded:true, exams:response.data });
        //errorMsgElement.innerHTML="Exam Scheduled Successfully";
              //return <Redirect to="/examdash" />
              })
              .catch(function (error) {
                //this.setState({ status:true});
                console.log(error);
                this.setState({ isLoaded:false});
    //errorMsgElement.innerHTML=error;
              });*/
    this.setState({ isLoaded: true, exams: response.data });
    /*for (var i = 0; i < this.state.exams.length; i++) {
      //if its an unscheduled exam
    Http.defaults.headers.common['Authorization'] = this.state.user.token;
      if (this.state.exams[i].date === undefined) {
        var api = 'user-api/userua/' + this.state.exams[i].id;
        const resp = await Http.get(api);
        for (var j = 0; j < resp.data.length; j++) {
          if (this.state.user.id == resp.data[j].id) {
            const updatedExams = [...this.state.exams];
            //updatedExams[i].status = resp.data[j].answer;
            if ((resp.data[j].attendance > 0))
              updatedExams[i].status = "Ongoing";
            if (resp.data[j].answer != "")
              updatedExams[i].status = "Completed";
            this.setState({ exams: updatedExams });
          }
          // this.getExamStatus(this.state.exams[i].id,this.state.user.id);
        }
        // this.state.exams[i].status=this.getExamStatus(this.state.exams[i].id,this.state.user.id);
      }
      else { //student scheduled exam
        const updatedExams = [...this.state.exams];
        updatedExams[i].date = updatedExams[i].exam_date;
        updatedExams[i].start_time = updatedExams[i].exam_time;
        this.setState({ exams: updatedExams });
      }
    }*/
    this.state.link = "/verifyhelp";
  }
  async componentDidMount() {
    var api;
    if(this.state.isLogged && this.state.user.role=="invig") {
      api = 'user-api/userd/'+this.state.user.id;
    }
    else {
      api = 'user-api/user/'+this.state.user.id;
    }
    Http.defaults.headers.common['Authorization'] = this.state.user.token;
    const response = await Http.get(api);
    //TODO: Add reset passwords here also is it required for students also?
    this.setState({ isLoaded: true, users: response.data });
    //console.log(this.state.users);
  }

  //FIDO Change
  getAssertion = () => {
    this.setState({ security_button: "Wait for prompt" });
    Http.get('/user/' + this.state.email + '/exists', {}, null, 'json').then(response => {
      console.log(response);
    })
      .then(() => {

        //var user_verification = $('#select-verification').find(':selected').val();            
        //            //var txAuthSimple_extension = $('#extension-input').val();
        //
        Http.get('/assertion/' + this.state.email + '?userVer=discouraged&txAuthExtension=', {
          userVer: 'discouraged',
          txAuthExtension: ''
        }, null, 'json')
          .then(makeAssertionOptions => {
            console.log("Assertion Options:");
            console.log(makeAssertionOptions);
            makeAssertionOptions.data.publicKey.challenge = this.bufferDecode(makeAssertionOptions.data.publicKey.challenge);
            makeAssertionOptions.data.publicKey.allowCredentials.forEach(listItem => {
              listItem.id = this.bufferDecode(listItem.id)
            });
            console.log(makeAssertionOptions.data);
            navigator.credentials.get({
              publicKey: makeAssertionOptions.data.publicKey
            })
              .then(credential => {
                console.log(credential);
                this.verifyAssertion(credential);
              }).catch(err => {
                console.log(err.name);
              });
          });
      })
      .catch(error => {
        if (!error.exists) {
          //alert("User not found, try registering one first!");
        }
        return;
      });
  }
  componentWillUnmount() {
    //console.log(localStorage.getItem('exam1'));
    //console.log(localStorage.getItem('user'));
  }
  toggleUserMenu() {
    this.setState({ userMenu: !this.state.userMenu });
  }
  toggleMenu() {
    this.setState({ menu: !this.state.menu });
  }
  toLocal = (utctime) => { var x = new Date(Date.parse("1970-01-01T" + utctime.toString() + "Z")); x += this.state.user.timezone * 60000; return (x.toLocaleString().slice(16, 24)); }

  async getExamStatus(exam_id, user_id) {
    var api = 'user-api/userua/' + exam_id;
    const response = await Http.get(api);
    for (var i = 0; i < response.data.length; i++) {
      if (user_id == response.data[i].id)
        return response.data[i].answer;
    }
    //console.log(response.data.answer);
    //alert(response.data.answer);
    //return response.data.answer;
  }

  render() {
    if(this.state.isLogged) {
    const show = this.state.menu ? "active" : "";
    const showMenu = this.state.menu ? "content-active" : "";
    const usershow = this.state.userMenu ? "show" : "";
    const { users } = this.state;
    let usersList = users.length > 0 &&
      users.filter(user => (user.email != null && user.invig_id == this.state.user.id)).map((user, i) => {
        return (
          <option key={i} value={user.email}>{user.first_name + "(" + user.email + ")"}</option>
        )
      }, this);

    return (
      <div>
        <meta charSet="utf-8" />
        <meta name="author" content="Softnio" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta name="description" content="@@page-discription" />
        {/* Fav Icon  */}
        <link rel="shortcut icon" href="images/favicon.png" />
        {/* Page Title  */}
        <title>Exam Dashboard | MonitorExam</title>
        {/* StyleSheets  */}
        <link rel="stylesheet" href="assets/css/style.css" />
        <link rel="stylesheet" href="assets/css/custom.css" />
        <link id="skin-default" rel="stylesheet" href="assets/css/theme.css?ver=1.2.0" />
        <div className="nk-app-root">
          {/* main @s */}
          <div className="nk-main ">
            {/* wrap @s */}
            <div className="nk-wrap ">
              <div className="nk-header nk-header-fixed is-light">
                <div className="container-lg wide-xl">
                  <div className="nk-header-wrap">
                    <div className="nk-header-brand">
                      <a href="#" className="logo-link">
                        <img className="logo-dark logo-img" src="images/monitor-exam-logo.png" srcSet="images/monitor-exam-logo-2x.png 2x" alt="logo-dark" />
                      </a>
                    </div>{/* .nk-header-brand */}
                    <div className="nk-header-tools">
                      <ul className="nk-quick-nav">
                        <li onClick={this.toggleUserMenu} className={"dropdown user-dropdown " + usershow}>
                          <a href="#" className="dropdown-toggle" data-toggle="dropdown" aria-expanded={this.state.userMenu}>
                            <div className="user-toggle">
                              <div className="user-avatar sm">
                                <img src={'https://d1bumj8k6ax755.cloudfront.net/uploads/' + this.state.user.reg_pic.split("'")[1]} class="regpic " alt="reg" style={{ transform: "scale(0.5)", left: "6px" }} />
                                <em className="icon ni ni-user-alt" />
                              </div>
                              <div className="user-name dropdown-indicator d-none d-sm-block">{this.state.user.first_name} {this.state.user.last_name}</div>
                            </div>
                          </a>
                          <div className={"dropdown-menu dropdown-menu-md dropdown-menu-right dropdown-menu-s1 " + usershow}>
                            <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                              <div className="user-card">
                                <div className="user-avatar">
      <span>{this.state.user.first_name.charAt(0)}{this.state.user.last_name.charAt(0)}</span>
                                </div>
                                <div className="user-info">
                                  <span className="lead-text">{this.state.user.first_name} {this.state.user.last_name}</span>
                                  <span className="sub-text">{this.state.user.email}</span>
                                </div>
                                <div className="user-action">
                                  <a className="btn btn-icon mr-n2" href="#"><em className="icon ni ni-setting" /></a>
                                </div>
                              </div>
                            </div>
                            <div className="dropdown-inner">
                              <ul className="link-list">
                                <li><a href="\viewprofile"><em className="icon ni ni-user-alt" /><span>View Profile</span></a></li>
                                <li><a href="/security"><em className="icon ni ni-setting-alt" /><span>Security Setting</span></a></li>
                                <li><a href="#"><em className="icon ni ni-activity-alt" /><span>Login Activity</span></a></li>
                              </ul>
                            </div>
                            <div className="dropdown-inner">
                              <ul className="link-list">
                                <li><a href="\newlogin"><em className="icon ni ni-signout" /><span onClick={() => localStorage.clear()}>Sign Out</span></a></li>
                              </ul>
                            </div>
                          </div>
                        </li>{/* .dropdown */}
                        <li onClick={this.toggleMenu} className="d-lg-none">
                          <a href="#" className={"toggle nk-quick-nav-icon mr-n1 " + this.state.menu ? "active" : ""} data-target="sideNav"><em className="icon ni ni-menu" /></a>
                        </li>
                      </ul>{/* .nk-quick-nav */}
                    </div>{/* .nk-header-tools */}
                  </div>{/* .nk-header-wrap */}
                </div>{/* .container-fliud */}
              </div>
              {/* main header @e */}
              {/* content @s */}
              <div className="nk-content ">
                <div className="container wide-xl">
                  <div className="nk-content-inner">
                    <div className={"nk-aside " + showMenu} data-content="sideNav" data-toggle-overlay="true" data-toggle-screen="lg" data-toggle-body="true">
                      <div className="nk-sidebar-menu" data-simplebar>
                        {/* Menu */}
                        <ul className="nk-menu">
                          <li style={{ display: this.state.user.role == 'invig' ? "block" : "none" }} className="nk-menu-item">
                            <a href="\newdashboard" className="nk-menu-link">
                              <span className="nk-menu-icon"><em className="icon ni ni-users" /></span>
                              <span className="nk-menu-text">Student Dashboard</span>
                            </a>
                            <ul className="nk-menu-sub">
                              <li className="nk-menu-item "><a href="\livestudentdash" className="nk-menu-link"><span className="nk-menu-text">Live Students</span></a></li>
                              <li className="nk-menu-item "><a href="\newdashboard" className="nk-menu-link"><span className="nk-menu-text">All Students</span></a></li>
		            </ul>
                          </li>
                          <li className="nk-menu-item">
                            <a href="\testdash" className="nk-menu-link">
                              <span className="nk-menu-icon"><em className="icon ni ni-file-docs" /></span>
                              <span className="nk-menu-text">Exam Dashboard</span>
                            </a>
                            <ul className="nk-menu-sub">
                              <li className="nk-menu-item "><a href="\flexi" className="nk-menu-link"><span className="nk-menu-text">Flexi Exams</span></a></li>
                              <li className="nk-menu-item active"><a href="\unscheduled" className="nk-menu-link"><span className="nk-menu-text">Anytime Exams</span></a></li>
                            </ul>
                          </li>
                          <li className="nk-menu-item">
                            <a href="\newlogin" className="nk-menu-link">
                              <span className="nk-menu-icon"><em className="icon ni ni-signout" /></span>
                              <span onClick={() => localStorage.clear()} className="nk-menu-text">Log Out</span>
                            </a>
                          </li>
                        </ul>
                      </div>{/* .nk-sidebar-menu */}
                      <div className="nk-aside-close">
                        <a href="#" className="toggle" data-target="sideNav"><em className="icon ni ni-cross" /></a>
                      </div>{/* .nk-aside-close */}
                    </div>{/* .nk-aside */}
                    <div className="nk-content-body">
                      <div className="nk-content-wrap">
                        <div className="">
                          <div className="nk-block-between-md g-4">
                            <div className="nk-block-head-content">
                              <h2 className="nk-block-title">Exam Dashboard</h2>
                              <h5 className="nk-block-title fw-normal">Anytime Exams</h5>
                              <div className={"nk-block-des " + (this.state.user.role == "invig" ? "" : "noactive")}>
                                <p>You can add exams. For removing please mail care@monitorexam.com</p>
                              </div>
                              <div className={"nk-block-des " + (this.state.user.role == "invig" ? "noactive" : "")}>
                              </div>
                            </div>
                            <div className={"nk-block-head-content " + (this.state.user.role == "invig" ? "" : "noactive")}>
                              <ul className="nk-block-tools gx-3">
                                <li><a href="/newform" className="btn btn-primary">+Exam</a></li>
                              </ul>
                            </div>
                          </div>
                        </div>{/* .nk-block-head */}
                        <div className="nk-block">
                          <p><span id="errorMsg"></span></p>
                          <div className="card ">
                            <table className="table table-member">
                              <thead className="tb-member-head thead-light">
                                <tr className="tb-member-item">
                                  <th className="tb-member-type " m>
                                    <span className="overline-title">ID</span>
                                  </th>
                                  <th className="tb-member-type tb-col-sm">
                                    <span className="overline-title">Name</span>
                                  </th>
                                  <th className="tb-member-action">
                                    <span className="overline-title">Duration(In Min)</span>
                                  </th>
                                  <th>
                                  </th>
                                  <th className="tb-member-action">
                                    <span className="overline-title d-md-inline-block pr-2">Schedule<br /> Date &amp; Time</span>
                                    <span className="overline-title d-md-none">Details</span>
                                  </th>
                                  <th className="tb-member-action">
                                  </th>
                                  <th className="tb-member-action">
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="tb-member-body">
                                <>
                                  {this.state.exams.filter(exam => ((exam.date === undefined) && (exam.exam_date == undefined) && ((exam.invig_id == this.state.user.id) || (exam.invig_id == this.state.user.invig_id))&&(exam.platform!="Flexi"))).map(exam => (
                                    <tr className="tb-member-item" id={"unscheduled exam" + exam.id}>
                                      <td className="tb-member-info d-md-none">
                                        <span className="contact d-md-none">
                                          {exam.id}
                                        </span>
                                        <span className="contact d-md-none">
                                          <a href={exam.link} target="blank"> {exam.name} </a>
                                        </span>
                                        <span className="sub-text d-md-none">
                                          {exam.platform}
                                        </span>
                                        <span className="sub-text d-none">
                                          <input type='date' className={"form-control form-control-lg date-picker "} placeholder='Enter Date' name='date' style={{ display: this.state.showDate ? "block" : "none" }} onChange={this.handleChange}></input>
                                          <input type='time' className={"form-control form-control-lg time-picker "} placeholder='Enter Start Time' name='start_time' style={{ display: this.state.showDate ? "block" : "none" }} onChange={this.handleChange}></input>
                                        </span>
                                        <span className=" d-none">
                                          <input type='date' className={"form-control form-control-lg date-picker "} placeholder='Enter Date' name='date' style={{ display: this.state.showDate ? "block" : "none" }} onChange={this.handleChange}></input>
                                          <input type='time' className={"form-control form-control-lg time-picker "} placeholder='Enter Start Time' name='start_time' style={{ display: this.state.showDate ? "block" : "none" }} onChange={this.handleChange}></input>
                                        </span>
                                      </td>
                                      <td className="tb-member-type tb-col-sm">
                                        <span>{exam.id}</span>
                                      </td>
                                      <td className="tb-member-type tb-col-sm">
                                          <a href={exam.link} target="blank"> {exam.name} </a>
                                      </td>
                                      <td className="tb-member-role">
                                        <span>{exam.duration}</span>
                                      </td>
                                      <td class="tb-member-role" style={{ display: this.state.user.role == 'invig' ? "" : "none" }}>
                                        <div class="row ml-4 mr-4">
                                          <select name='user_id' style={{ display: this.state.user.role == 'invig' ? "" : "none", width: "fit-content" }} onChange={this.handleChange} className="form-control form-control-lg" id="user_id" placeholder="Student Email" >
                                            {usersList}
                                          </select>
                                        </div>
                                        <div class="row ml-4 mr-4">
                                          <a href='#' style={{ display: this.state.user.role == 'invig' ? "" : "none" }} onClick={() => this.handleClick(exam)} className="btn btn-icon btn-secondary pl-2 pr-2">
                                            <em className="ni ni-user-add"></em>
                                          </a>
                                        </div>
                                      </td>
                                      <td className="tb-member-action">
                                        <div class="row ">
                                          <input type='date' className={"form-control date-picker col-5 "} placeholder='Enter Date' name='date' onChange={this.handleChange}></input>
                                          <input type='time' className={"form-control time-picker ml-2 col-5"} placeholder='Enter Start Time' name='start_time' onChange={this.handleChange}></input>
                                        </div>
                                        <div class="row">
                                          <a href='#' style={{ display: this.state.user.role == 'invig' ? "" : "" }} className="btn btn-icon btn-secondary col-11 mr-1" onClick={() => this.scheduleExam(exam)}>
                                            <em className="ni ni-calendar-booking-fill pr-2 pl-2"></em>
                                          </a>
                                        </div>
                                      </td>
                                      <td className="tb-member-action">
                                        <div class="row ">
                                          <a href={exam.status != "Completed" ? "/verifyhelp" : "#"} onClick={this.openfullscreen(document.documentElement)} id="startExam" style={{ display: this.state.user.role == 'invig' ? "none" : "" }} className="btn btn-icon btn-primary ml-3 pl-1" onClick={() => this.startExam(exam)}>
                                            {(exam.status != "Completed") ? ((exam.status == "") ? "Start Now" : "Resume") : "Done"}
                                          </a>
                                        </div>
                                        <div class="">
                                          <a href='/newmonidash' style={{ display: this.state.user.role == 'invig' ? "" : "none" }} onClick={() => this.handleClick(exam)} className="btn btn-primary ml-3 pl-3 pr-3" >
                                            View<em className="ni ni-eye-fill p-2 d-none"></em>
                                          </a>
                                        </div>
                                      </td>

                                    </tr>
                                  ))}
                                </>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      {/* footer @e */}
                    </div>
                  </div>
                </div>
              </div>
              {/* content @e */}
            </div>
            {/* wrap @e */}
          </div>
          {/* main @e */}
        </div>
        {/* app-root @e */}
        {/* JavaScript */}
      </div>
    );
  }
    else
      <NewLogin />
  }
}
export default UnscheduledExamDashboard
