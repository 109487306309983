import React, {Component} from 'react'
import ReactDOM from 'react-dom'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Jumbotron from 'react-bootstrap/Jumbotron'
import Http from 'axios'
import Profile from './Profile'
import {Redirect} from 'react-router-dom'
import Adapter from 'webrtc-adapter'
import NewExamDashboard from '../Dashboard/NewExamDashboard'
import Card from 'react-bootstrap/Card'
import mixpanel from 'mixpanel-browser'
import Base64js from 'base64-js'

class WebSecurity extends React.Component{
	constructor(){
		super();
		this.state={
			WebAuthnSupport:false,
		    first_name:'rajiv',
			file:"",captured:false,
			last_name:"",
			email:"she@email.com",
			mobile:"1111122222",
			id:1,
			password:"password",
			rePassword:"password",
			status:true,
			captured:false,
			otp:123456,
			displayToast:true,
			button_state:"Capture",
			isLogged:false,
			isLoaded:false, 
			found:false,
			showDate:false,
			userMenu:false,
			menu:false
		};
		this.handleCapture=this.handleCapture.bind(this);
		this.handleSubmit=this.handleSubmit.bind(this);
	    this.handleClick = this.handleClick.bind(this);
		this.toggleMenu=this.toggleMenu.bind(this);
		this.toggleUserMenu=this.toggleUserMenu.bind(this);

	}
	bufferDecode(value) {
	    return Uint8Array.from(atob(value), c => c.charCodeAt(0));
	}
        componentWillMount() {
		this.state.WebAuthnSupport=(window.PublicKeyCredential===undefined)
		console.log("Webauthn Support is "+ !this.state.WebAuthnSupport);
		const user = JSON.parse(localStorage.getItem('user'));
		if(user!==undefined) {
		     this.state.user=user;
                     this.state.isLogged=true;
		}
		else{
                    console.log("User not Logged in");
		}
	}
	// This should be used to verify the auth data with the server
	// step 5 of the mozilla doc
	registerNewCredential(newCredential) {
	         // Move data into Arrays incase it is super long
	         let attestationObject = new Uint8Array(newCredential.response.attestationObject);
	         let clientDataJSON = new Uint8Array(newCredential.response.clientDataJSON);
		 //globally unique identifier of the user
	         let rawId = new Uint8Array(newCredential.rawId);
		 rawId = this.bufferEncode(rawId);
	
	         Http.post('makeCredential', { 
		     id: newCredential.id,
	             rawId: rawId,
	             type: newCredential.type,
		     response: {
		        attestationObject: this.bufferEncode(attestationObject),
		        clientDataJSON: this.bufferEncode(clientDataJSON),
		     },
		 })
		 .then(res=>{
			 console.log(res);
		 })
	         .catch(error=> {
			 console.log(error);
		 });
	 } 
	// Encode an ArrayBuffer into a base64 string.
	// TODO: To verify this
	 bufferEncode(value) {
           // return Buffer.from(value).toString('base64');
	     return Base64js.fromByteArray(value)
	        .replace(/\+/g, "-")
	        .replace(/\//g, "_")
	        .replace(/=/g, "");
	 }
	checkUserExists() {
		    Http.get('/user/' + this.state.user.email + '/exists', {}, null, 'json')
		        .then(response=> {
				console.log("User Exists");
				            return true;
				        })
			.catch(error=> {
				console.log("User does not Exist");
				            return false;
				       });
	}
	async handleClick(){
		const step1=document.getElementById("step1");
		const listep1=document.getElementById("listep1");
		const countstep1=document.getElementById("countstep1");
		const step2=document.getElementById("step2");
		const listep2=document.getElementById("listep2");
		const countstep2=document.getElementById("countstep2");
		const step3=document.getElementById("step3");
		const listep3=document.getElementById("listep3");
		const countstep3=document.getElementById("countstep3");
		const errorMsgElement = document.querySelector('span#errorMsg');
                
	    const headers = {
		      'Access-Control-Allow-Origin': '*',
		      'Access-Control-Allow-Methods': 'GET,HEAD,OPTIONS,POST,PUT',
		      'Access-Control-Allow-Headers': 'Origin,X-Requested-With,Content-Type,Accept,Authorization',
		};
            const url='makeCredential/'
			+ this.state.user.email
			+'?attType=direct&authType=&userVerification=required&residentKeyRequirement=false&txAuthExtension=';

            Http.get( url,{headers})
		.then(makeCredentialOptions=> {            
	            makeCredentialOptions.data.publicKey.challenge = this.bufferDecode(makeCredentialOptions.data.publicKey.challenge);
	            makeCredentialOptions.data.publicKey.user.id = this.bufferDecode(makeCredentialOptions.data.publicKey.user.id);
	            if (makeCredentialOptions.data.publicKey.excludeCredentials) {
		       for (var i = 0; i < makeCredentialOptions.data.publicKey.excludeCredentials.length; i++) {
		           makeCredentialOptions.data.publicKey.excludeCredentials[i].id = this.bufferDecode(makeCredentialOptions.data.publicKey.excludeCredentials[i].id);
		       }
		    }
		    console.log("Credential Creation Options");
		    console.log(makeCredentialOptions.data);
		    navigator.credentials.create({
		         publicKey: makeCredentialOptions.data.publicKey
		    }).then(newCredential=> {
			 errorMsgElement.innerHTML="Security Key Added Successfully";
		         console.log("PublicKeyCredential Created");
		         console.log(newCredential);
		         this.state.createResponse = newCredential;
		         this.registerNewCredential(newCredential);
			 this.state.user.fido_auth_enabled=true;
		    }).catch(err=> {
		         console.log(err);
		    });
		});
              Http.defaults.headers.common['Authorization'] = this.state.user.token;
              Http.put('user-api/user/' + this.state.user.id, {
              "fido_auth_enabled": true,
              }, {
                  headers: {
                      "Content-Type": "application/json"
                  }
              })
              .then(res => {
                  console.log("User updated Successfully");
              })
              .catch(function(error) {
                  errorMsgElement.innerHTML = "Failed to Add Security Key";
                  console.log(error);
              });
		//TODO: make credential will use the value entered by the user
	/*	         step2.classList.add("noactive");
		         listep2.classList.remove("active");
		         countstep2.classList.remove("active-num");
		         step3.classList.remove("noactive");
		         listep3.classList.add("active");
		         countstep3.classList.add("active-num");
            console.log("form submitted");*/
	}

	handleChange = (event)=>{
		let nam=event.target.name;
		let val=event.target.value;
		this.setState({[nam]:val});
	}
	handleSubmit = (event)=>{
		const x=new Date();
		event.preventDefault();
		const errorMsgElement = document.querySelector('span#errorMsg');
                const filename = document.querySelector('input#filename');
		if(filename.value)
		{
			this.state.file=filename.value;
			console.log(this.state.file);
		}
		if(this.state.password===this.state.rePassword)
		{
		        Http.post('user-api/user',{
		          "first_name":this.state.user.first_name,
		          "last_name":this.state.user.last_name,
		          "email":this.state.user.email,
		          "mobile":this.state.user.mobile,
		          "password":this.state.user.password,
			  "timezone":x.getTimezoneOffset(),
			  "last_login":x.toISOString().slice(0,19).replace('T','  '),
			  "class":1,
			  "school":1,
			  "lat":this.state.user.lat,
			  "lng":this.state.user.lng,
			  "invig_id":9999,
			  "role":"invig",
			  "reg_pic":this.state.user.file
		        }, {
		          headers: {
		            "Content-Type": "application/json"
		          },
			  //timeout:100
		        })
		      .then(res => {
                          const response = res.data;
			  if(response.id===undefined)
			  {
				  errorMsgElement.innerHTML="User Exists";
				  errorMsgElement.classList.remove("badge-outline-success");
				  errorMsgElement.classList.add("badge-outline-warning");	  
				  return;
			  }
			  else
			  {
                               this.setState({ user:response,status:false});
			       errorMsgElement.innerHTML="New User Created Successfully";
			       errorMsgElement.classList.remove("badge-outline-info");
			       errorMsgElement.classList.add("badge-outline-success");	  
	       		       localStorage.setItem('user',JSON.stringify(response)); 
	                       return <Redirect to="/newprofile" />
                         }
		      })
			.catch(error=>{console.log(error);});
		}
		else {
		       alert("The passwords did not match");
		       errorMsgElement.innerHTML="The passwords did not match";
		       errorMsgElement.classList.remove("badge-outline-info");
		       errorMsgElement.classList.add("badge-outline-warning");	 
		}
	}
        showPosition = (position) => {
		this.state.lat=position.coords.latitude;
		this.state.lng=position.coords.longitude;
	}
/*	handleSetup = (event) => {
		// handles JSON.stringify/parse
		 //const signaling = new SignalingChannel();
		 const constraints = {audio: true, video: true};
		 const configuration = {iceServers: [{urls: 'stuns:stun.example.org'}]};
		 const pc = new RTCPeerConnection(configuration);
		
		 // send any ice candidates to the other peer
		// pc.onicecandidate = ({candidate}) => signaling.send({candidate});

		
		 // let the "negotiationneeded" event trigger offer generation
		 pc.onnegotiationneeded = async () => {
		   try {
		       await pc.setLocalDescription(await pc.createOffer());
		     // send the offer to the other peer
		       //signaling.send({desc: pc.localDescription});
		       } catch (err) {
		       console.error(err);
		     }
		 };
	
           // once remote track media arrives, show it in remote video element
		  pc.ontrack = (event) => {
		                         // don't set srcObject again if it is already set.
		                           /*if (remoteView.srcObject) return;
		                             remoteView.srcObject = event.streams[0];
		                             };
		                             // call start() to initiate
		                             async function start() {
		                             try {
		                               // get local stream, show it in self-view and add it to be sent
		                                 const stream =
		                                 await navigator.mediaDevices.getUserMedia(constraints);
		                                 stream.getTracks().forEach((track) =>
		                                 pc.addTrack(track, stream));
		                                 selfView.srcObject = stream;
		                               } catch (err) {
		                                   console.error(err);
		                               }
		                             }

		                         //signaling.onmessage = async ({desc, candidate}) => {
		                         try {
		                           if (desc) {
		                           // if we get an offer, we need to reply with an answer
		                             if (desc.type === 'offer') {
		                               await pc.setRemoteDescription(desc);
		                               const stream =
		                               await navigator.mediaDevices.getUserMedia(constraints);
		                               stream.getTracks().forEach((track) =>
		                               pc.addTrack(track, stream));
		                               await pc.setLocalDescription(await pc.createAnswer());
		                               //signaling.send({desc: pc.localDescription});
		                           } else if (desc.type === 'answer') {
		                           await pc.setRemoteDescription(desc);
		                           } else {
		                           console.log('Unsupported SDP type.');
		                           }
		                         } else if (candidate) {
		                         await pc.addIceCandidate(candidate);
		                         }
		                       } catch (err) {
		                         console.error(err);
		                       }
		                       };
	}
	async saveFile=()=> {

		  // create a new handle
	   const newHandle = await window.showSaveFilePicker();
	
           //create a FileSystemWritableFileStream to write to
	   const writableStream = await newHandle.createWritable();
	         // write our file
	   await writableStream.write(imgBlob);
		
	         // close the file and write the contents to disk.
	   await writableStream.close();
        }
	 let saveImage = (img_name) => {
		         }
		
	*/
	handleCapture = (event) => {
	        const video = document.getElementById('video');
                const canvas = document.getElementById('canvas');
                const snap = document.getElementById("snap");
                const errorMsgElement = document.querySelector('span#errorMsg');//error display
                const filename = document.querySelector('input#filename');//hidder var
		const step1=document.getElementById("step1");
		const listep1=document.getElementById("listep1");
		const countstep1=document.getElementById("countstep1");
		const step2=document.getElementById("step2");
		const listep2=document.getElementById("listep2");
		const countstep2=document.getElementById("countstep2");
		const step3=document.getElementById("step3");
		const listep3=document.getElementById("listep3");
		const countstep3=document.getElementById("countstep3");
         {
		 errorMsgElement.innerHTML="Please wait, We are validating your picture";
                //document.querySelector('#canvas').css('display', 'block');
                //document.querySelector('.camera').css('display', 'none');
                document.querySelector('#startbutton').classList.add('save-img');
                //document.querySelector('.auth-animated-alert').css('display', 'block');
    
                //var btntxt = document.querySelector('#startbutton').text();
                //alert(btntxt);
                if( this.state.button_state == "Next") {
                // document.querySelector('.auth-animated-alert').css('display', 'none');
                //alert('clicked');
                document.querySelector('.step1').classList.add('noactive'); 
                document.querySelector('.step2').classList.remove('noactive');
                document.querySelector('.pro-num.count-step1').classList.remove('active-num');
                document.querySelector('.pro-num.count-step2').classList.add('active-num');
                document.querySelector('.progressbar .count-step1').classList.remove('active');
                document.querySelector('.progressbar .count-step2').classList.add('active');
    }
    
    
    
    /*document.getElementById("startbutton").id = "save-img";
    document.querySelector('.save-img').removeAttr('id');
    document.querySelector('.save-img').setAttribute('id','save-img');*/
}       
		//this.setState({captured:true});
                var context = canvas.getContext('2d');
                context.drawImage(video, 0, 0, 480, 360);
                canvas.toBlob(function(blob) {
                var newImg = document.getElementById('imageSrc'),
                
                url = URL.createObjectURL(blob);
                newImg.src = url;
            
                var data = new FormData();
                var file = new File([blob],"selfie");//name the file
                data.append('file', file);//append file to form data
                if (blob) {
                    errorMsgElement.innerHTML = "Wait as we find whether you are a human ....";
                   
                Http.post('user-api/usera', data, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
		        //timeout:500 TODO to add timeout
                })
                .then(res => {
                    errorMsgElement.innerHTML = res.data;
		            if(res.data.includes('not'))
		            {
			        setTimeout(() => {errorMsgElement.innerHTML = "Ensure that your face is visible on camera";
					errorMsgElement.classList.remove("badge-outline-info");
					errorMsgElement.classList.add("badge-outline-warning");
				}, 2000);
		            }
		            else
		            {
		                filename.value = res.data.split(' ',1);//TODO store this filename in db
		                setTimeout(() => {errorMsgElement.innerHTML = "Face Detected. Fill the form to Register";
				errorMsgElement.classList.remove("badge-outline-info");
				errorMsgElement.classList.add("badge-outline-success"); }, 2000);          
		    		step1.classList.add("noactive");
		    		listep1.classList.remove("active");
		    		countstep1.classList.remove("active-num");
		    		step2.classList.remove("noactive");
		    		listep2.classList.add("active");
		    		countstep2.classList.add("active-num");
		            }
                })
                .catch(function(error) {
                    errorMsgElement.innerHTML = error;
                    console.log(error);
                });
            }   
            //this.setState({profilePic:blob});
         });
		if(errorMsgElement.innerHTML.includes("Ensure"))
		{
		    this.setState({captured:false});
		    console.log("Face detection test failed");
		    this.setState({button_state:"Verification Failed"});
		    document.getElementById("startbutton").innerHTML(this.state.button_state);
		}
		else if(errorMsgElement.innerHTML.includes("Please"))
		{
		    this.setState({captured:false});
		    this.setState({button_state:"Capture"});
		}
		else
	        {
		    this.setState({captured:true});
		    this.setState({button_state:"Next"});
		    document.getElementById("startbutton").innerHTML(this.state.button_state);
		    //window.location.reload();
		}
		if(filename.value)
		{
			this.setState({file:filename.value});
			console.log(this.state.file);
		}
        //TODO to be removed
        if (window.navigator.msSaveBlob) {
            window.navigator.msSaveBlob(canvas.msToBlob(), "pic" + this.state.user.id);
        } else {}
                //saveFile(imageData);
	/*var data =new FormData();
		data.append('file',"selfie.png");
		console.log(data);
		this.setState({fileLivePic:"selfie.png"});
		console.log(this.state.fileLivePic);
	        Http.post('user-api/usera',data,{
		        headers: {
		          'Content-Type': 'multipart/form-data'
		        }
		    })
	        .then(res => {
			errorMsgElement.innerHTML=res.data;
		})
                .catch(function (error) {
                     console.log(error);
                 });

		dataURL.replace(/^data:image\/(png|jpg);base64,/, "")
		var imageData=btoa(dataURL);
                //saveFile(imageData);

		let src = window.cv.imread('canvas');
		let dst = new window.cv.Mat();
		// You can try more different parameters
		window.cv.cvtColor(src, dst, window.cv.COLOR_RGBA2GRAY, 0);
		window.cv.imshow('canvas', dst);
		src.delete(); dst.delete();
		//
*/
	
	}

	toggleUserMenu() {
    this.setState({userMenu:!this.state.userMenu});  
	}
	toggleMenu() {
    this.setState({menu:!this.state.menu});  
	}
  render() {
	  const show = this.state.menu ? "active" : "";
	  const showMenu = this.state.menu ? "content-active" : "";
	  const usershow = this.state.userMenu ? "show" : "";
	if(this.state.status)
	{
    return (
      <div>
        <meta charSet="utf-8" />
        <meta name="author" content="Softnio" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta name="description" content="@@page-discription" />
        {/* Fav Icon  */}
        <link rel="shortcut icon" href="../images/favicon.png" />
        {/* StyleSheets  */}
        <link rel="stylesheet" href="../assets/css/style.css?ver=1.2.0" />
        <link rel="stylesheet" href="../assets/css/custom.css" />
        <link id="skin-default" rel="stylesheet" href="../assets/css/theme.css?ver=1.2.0" />
        {/* Page Title  */}
        <title>Registration | Monitor Exam</title>
        {/*Header*/}
        <header className="">
          <div className="container">
              <div className="nk-header nk-header-fixed is-light">
                <div className="container-lg wide-xl">
                  <div className="nk-header-wrap">
                    <div className="nk-header-brand">
            <a href className="logo"><img src="../images/monitor-exam-logo.png" alt="logo" width={180} /></a>
	    </div>
                    <div className="nk-header-tools">
                      <ul className="nk-quick-nav">
                        <li onClick={this.toggleUserMenu} className={"dropdown user-dropdown " + usershow}>
                          <a href="#" className="dropdown-toggle" data-toggle="dropdown" aria-expanded={this.state.userMenu}>
                            <div className="user-toggle">
                              <div className="user-avatar sm">
                                <img src={'https://d1bumj8k6ax755.cloudfront.net/uploads/' + this.state.user.reg_pic.split("'")[1]} class="regpic " alt="reg" style={{ transform: "scale(0.5)", left: "6px" }} />
                                <em className="icon ni ni-user-alt" />
                              </div>
                              <div className="user-name dropdown-indicator d-none d-sm-block">{this.state.user.first_name} {this.state.user.last_name}</div>
                            </div>
                          </a>
                          <div className={"dropdown-menu dropdown-menu-md dropdown-menu-right dropdown-menu-s1 "+usershow}>
                            <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                              <div className="user-card">
                                <div className="user-avatar">
                                  <span>AB</span>
                                </div>
                                <div className="user-info">
                                  <span className="lead-text">{this.state.user.first_name} {this.state.user.last_name}</span>
                                  <span className="sub-text">{this.state.user.email}</span>
                                </div>
                                <div className="user-action">
                                  <a className="btn btn-icon mr-n2" href="#"><em className="icon ni ni-setting" /></a>
                                </div>
                              </div>
                            </div>
                            <div className="dropdown-inner">
                              <ul className="link-list">
                              <li><a href="\viewprofile"><em className="icon ni ni-user-alt" /><span>View Profile</span></a></li>
                                <li><a href="/security"><em className="icon ni ni-setting-alt" /><span>Security Setting</span></a></li>
                                <li><a href="#"><em className="icon ni ni-activity-alt" /><span>Login Activity</span></a></li>
                              </ul>
                            </div>
                            <div className="dropdown-inner">
                              <ul className="link-list">
                                <li><a href="\newlogin"><em className="icon ni ni-signout" /><span onClick={()=>localStorage.clear()}>Sign Out</span></a></li>
                              </ul>
                            </div>
                          </div>
                        </li>{/* .dropdown */}
                        <li onClick={this.toggleMenu} className="d-lg-none">
                          <a href="#" className={"toggle nk-quick-nav-icon mr-n1 "+this.state.menu?"active":""} data-target="sideNav"><em className="icon ni ni-menu" /></a>
                        </li>
                      </ul>{/* .nk-quick-nav */}
                    </div>{/* .nk-header-tools */}
          </div>
          </div>
          </div>
          </div>
        </header>
        {/* app body @s */}
        <div className="nk-app-root">
              <div className="nk-block nk-block-middle nk-auth-body">
              </div>{/* .nk-block */}
              {/* nk-block */}
              <div className="nk-content ">
                <div className="container wide-xl">
                  <div className="nk-content-inner">
                    <div className={"nk-aside "+showMenu} data-content="sideNav" data-toggle-overlay="true" data-toggle-screen="lg" data-toggle-body="true">
                      <div className="nk-sidebar-menu" data-simplebar>
                        {/* Menu */}
                        <ul className="nk-menu">
                          <li className="nk-menu-heading">
                            <h6 className="overline-title">Menu</h6>
                          </li>
                          <li style={{display:this.state.user.role=='invig'? "block":"none"}} className="nk-menu-item">
                            <a href="\newdashboard" className="nk-menu-link">
                              <span className="nk-menu-icon"><em className="icon ni ni-users" /></span>
                              <span className="nk-menu-text">Student Dashboard</span>
                            </a>
                            <ul className="nk-menu-sub">
                              <li className="nk-menu-item "><a href="\livestudentdash" className="nk-menu-link"><span className="nk-menu-text">Live Students</span></a></li>
                              <li className="nk-menu-item "><a href="\newdashboard" className="nk-menu-link"><span className="nk-menu-text">All Students</span></a></li>
		            </ul>
                          </li>
                          <li className="nk-menu-item active">
                            <a href="\testdash" className="nk-menu-link">
                              <span className="nk-menu-icon"><em className="icon ni ni-file-docs" /></span>
                              <span className="nk-menu-text">Exam Dashboard</span>
                            </a>
                            <ul className="nk-menu-sub">
                              <li className="nk-menu-item"><a href="\flexi" className="nk-menu-link"><span className="nk-menu-text">Flexi Exams</span></a></li>
                              <li className="nk-menu-item"><a href="\unscheduled" className="nk-menu-link"><span className="nk-menu-text">Anytime Exams</span></a></li>
                            </ul>
                          </li>
                          <li className="nk-menu-item">
                            <a href="\newlogin" className="nk-menu-link">
                              <span className="nk-menu-icon"><em className="icon ni ni-signout" /></span>
                              <span onClick={()=>localStorage.clear()} className="nk-menu-text">Log Out</span>
                            </a>
                          </li>
                        </ul>
                      </div>{/* .nk-sidebar-menu */}
                      <div className="nk-aside-close">
                        <a href="#" className="toggle" data-target="sideNav"><em className="icon ni ni-cross" /></a>
                      </div>{/* .nk-aside-close */}
                    </div>{/* .nk-aside */}
                        <div className="nk-block">
                    <h4 className="nk-block-title mt-40">Security and Privacy</h4>
                  <div className="row security w-max-550">
		                    <p><span id="errorMsg"></span></p>
                          <div className="card card-bordered">
                  <div id="security" className="row security w-max-550 mt-40">
	       	    <span id="errorMsg" className="badge badge-dim badge-outline-info "></span>
                    <div className="col-sm-4 form-group">
                    </div>
                    <div className="col-sm-6 form-group">
	            <h5 style={{display:this.state.user.fido_auth_enabled?"block":"none"}}>Modify Security Key</h5>
	            <h5 style={{display:this.state.user.fido_auth_enabled?"none":"block"}}>Add Security Key</h5>
	            <p> A security key is either a fingerprint scanner or Windows Hello Protocol</p>
                      <button style={{display:this.state.user.fido_auth_enabled?"none":"block"}} type="submit" onClick={this.handleClick} className="btn btn-lg btn-primary btn-block">Add Security Key</button>
                      <button style={{display:this.state.user.fido_auth_enabled?"block":"none"}} type="submit" onClick={this.handleClick} className="btn btn-lg btn-primary btn-block">Modify Security Key</button>
                    </div>
                  </div>
	    </div>{/*card card-bordered */}
            <div className="card card-bordered">
              <div className="reg-form m-auto">
                <form onSubmit={this.handleSubmit}>
                  <div id="step1" className="row step1 noactive">
                    <div className="col-sm-12 form-group">
                      <span className="badge badge-dim badge-outline-info "></span>
                      {/*<div><button id="startbutton" class="btn btn-lg btn-primary btn-block">Capture</button></div>*/}
                      <div className="camera video-wrap">
                        <video id="video" width="480px" height="360px" style={{display:this.state.captured?"none":"block"}} playsInline autoPlay>Video stream not availble</video>
		        <img id="imageSrc"  width="480px" height="360px" style={{display:this.state.captured?"block":"none"}}/>
                      </div>
                      <canvas id="canvas" width="480px" height="360px" style={{display:this.state.captured?"none":"none"}}></canvas>
		      <input type="hidden" name="filename" id="filename" />
                    </div>
                    <div className="col-sm-6"><a href="#" className="btn btn-outline-primary btn-block" onClick={()=>window.location.reload()} id="retake"><em className="icon ni ni-redo" /> Retake</a></div>
                    <div className="col-sm-6"><a href="#" className="btn btn-primary btn-block" id="startbutton" onClick={this.handleCapture}>{this.state.button_state}</a></div>
                  </div>
                  <div id="step2" className="row step2 noactive">
                    <div className="col-sm-6 form-group">
                      <span className="form-icon"><em className="icon ni ni-user" /></span>
                      <input type="text" className="form-control form-control-lg" id="first_name" placeholder="First Name*" name='first_name' onChange={this.handleChange} required/>
                    </div>
                    <div className="col-sm-6 form-group">
                      <span className="form-icon"><em className="icon ni ni-user" /></span>
                      <input type="text" className="form-control form-control-lg" id="last_name" placeholder="Last Name*" name='last_name' onChange={this.handleChange} required/>
                    </div>
                    <div className="col-sm-12 form-group">
                      <span className="form-icon"><em className="icon ni ni-mail" /></span>
                      <input type="email" className="form-control form-control-lg" id="email" placeholder="Email*" name='email' onChange={this.handleChange} required/>
                    </div>
                    <div className="col-sm-12 form-group">
                      <span className="form-icon"><em className="icon ni ni-call" /></span>
                      <input type="tel" className="form-control form-control-lg" id="mobile" placeholder="Mobile" name='mobile' onChange={this.handleChange} required/>
                    </div>
                    <div className="col-sm-12 form-group">
                      <button onClick={this.handleClick} className="btn btn-lg btn-primary btn-block">Register</button>
                    </div>
                  </div>
                  <div id="step3" className="row step3">
                    <div className="col-sm-4 form-group">
                    </div>
                    <div className="col-sm-6 form-group">
	            <h5>Change Password</h5>
                      <div className="form-control-wrap">
                        <span className="form-icon"><em className="icon ni ni-lock" /></span>
                        <a href="#" className="form-icon form-icon-right passcode-switch" data-target="password">
                          <em className="passcode-icon icon-show icon ni ni-eye" />
                          <em className="passcode-icon icon-hide icon ni ni-eye-off" />
                        </a>
                        <input type="password" className="form-control form-control-lg" name='password' id="password" placeholder="Password" onChange={this.handleChange} required/>
                      </div>
                    </div>
                    <div className="col-sm-2">
                    </div>
                    <div className="col-sm-4 form-group">
                    </div>
                    <div className="col-sm-6 form-group">
                      <div className="form-control-wrap">
                        <span className="form-icon"><em className="icon ni ni-lock" /></span>
                        <a href="#" className="form-icon form-icon-right passcode-switch" data-target="password">
                          <em className="passcode-icon icon-show icon ni ni-eye" />
                          <em className="passcode-icon icon-hide icon ni ni-eye-off" />
                        </a>
                        <input type="password" className="form-control form-control-lg" name='rePassword' id="rePassword" placeholder="Confirm Password" onChange={this.handleChange} required/>
                      </div>
                    </div>
                    <div className="col-sm-2">
                    </div>
                    <div className="col-sm-4">
                    </div>
                    <div className="col-sm-6 form-group">
                      <button type="submit" className="btn btn-lg btn-primary btn-block">Change Password</button>
                    </div>
                    <div className="col-sm-2">
                    </div>
                  </div>
                </form>{/* form */}
              </div>
	    </div>{/*card card-bordered */}
	    </div>{/*nk-content */}
	    </div>{/*nk-content */}
        </div>{/* app body @e */}
        </div>{/* app body @e */}
        </div>{/* app body @e */}
        </div>{/* app body @e */}
        {/* JavaScript */}
      </div>
    );
	} else{
		return (<NewExamDashboard user={this.state.user} />);
	}
  }
}
export default WebSecurity
