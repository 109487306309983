import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import { browserHistory } from 'react-router';
import Auth from './Auth/Auth';
import NewContact from './Home/NewContact';
import Support from './Home/Support';
import Home from './Home/WFHome';
import Product from './Home/Product';
import Contact from './Home/WFContact';

import InvigAuth from './Auth/InvigAuth';
import NewInvigAuth from './Auth/NewInvigAuth';
import ParentAuth from './Auth/ParentAuth';
import AisctAuth from './Auth/AisctAuth';
import Invite from './Form/Invite';
import MCQTest from './Form/MCQTest';
import NewInvite from './Form/NewInvite';
import NewLogin from './Auth/NewLogin';
import Confirm from './Auth/Confirm';
import WebAuthnLogin from './Auth/WebAuthnLogin';
import AuthFido from './Auth/AuthFido';
import WebAuthn from './Auth/WebAuthn';
import WebSecurity from './Auth/WebSecurity';
import Dashboard from './Dashboard/Dashboard';
import NewDashboard from './Dashboard/NewDashboard';
import ExamCompleted from './Dashboard/ExamCompleted';
import NewExamCompleted from './Dashboard/NewExamCompleted';
import UpcomingExamDashboard from './Dashboard/UpcomingExamDashboard';
import FlexiExamDashboard from './Dashboard/FlexiExamDashboard';
import AdminDashboard from './Dashboard/AdminDashboard';
import MonitorDashboard from './Dashboard/MonitorDashboard';
import NewMonitorDashboard from './Dashboard/NewMonitorDashboard';
import MonitorGridDashboard from './Dashboard/MonitorGridDashboard';
import LiveDashboard from './Dashboard/LiveDashboard';
import LiveStudentDashboard from './Dashboard/LiveStudentDashboard';
import AdminExamDashboard from './Dashboard/AdminExamDashboard';
import UnscheduledExamDashboard from './Dashboard/UnscheduledExamDashboard';
import PastExamDashboard from './Dashboard/PastExamDashboard';
import NewExamDashboard from './Dashboard/NewExamDashboard';
import ExamCardDashboard from './Dashboard/ExamCardDashboard';
import NewOngoingExamDashboard from './Dashboard/NewOngoingExamDashboard';
import Interview from './Auth/Interview';
import StartTest from './Auth/StartTest';

import NewTest from './Auth/NewTest';
import Form from './Form/Form';
import NewForm from './Form/NewForm';
import ViewExam from './Form/ViewExam';
import NotFound from './Home/NotFound';
import NewHome from './Home/NewHome';
import HiW from './Home/HiW';
import AboutUs from './Home/WFAbout';
import AISCT from './Home/AISCT';
import FAQ from './Home/FAQ';
import Success from './Home/Success';
import SubmitJotForm from './Home/SubmitJotForm';
import Cancel from './Home/Cancel';
import Failure from './Home/Failure';
import Pricing from './Home/WFPricing';
import Job from './Home/Job';
import HowItWorks from './Home/HowItWorks';
import Menu from './Menu/Menu';
import Header from './Home/Header';
import Footer from './Home/Footer';
import Video from './Chat/VideoChat';
import Profile from './Auth/Profile';
import NewProfile from './Auth/NewProfile';
import VerifyHelp from './Auth/VerifyHelp';
import ViewProfile from './Auth/ViewProfile';
import Wait from './Auth/Wait';
import NewWait from './Auth/NewWait';
import TimeComponent from './Util/TimeComponent';
import VerifyStudent from './Auth/Verify';
import ResetPassword from './Auth/ResetPassword';
import NewResetPassword from './Auth/NewResetPassword';
import Terms from './Auth/Terms';
import Privacy from './Auth/Privacy';
import SpeechRecognitionComponent from './Util/SpeechRecognitionComponent';

function App() {
  return (
    <div className="App">
      <div>
        <Router>
          <div>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/hiw">
              <HiW />
            </Route>
            <Route exact path="/about">
              <AboutUs />
            </Route>
            <Route exact path="/product">
              <Product />
            </Route>
            <Route exact path="/home">
              <Home />
            </Route>
            <Route exact path="/register">
              <AuthFido />
              <Footer />
            </Route>
            <Route exact path="/aisct">
              <AISCT />
              <Footer />
            </Route>
            <Route exact path="/iregister">
              <Menu dark="true" />
              <br />
              <br />
              <br />
              <br />
              <InvigAuth />
              <Footer />
            </Route>
            <Route exact path="/newiregister">
              <NewInvigAuth />
              <Footer />
            </Route>
            <Route exact path="/pregister">
              <Menu dark="true" />
              <br />
              <br />
              <br />
              <br />
              <ParentAuth />
              <Footer />
            </Route>

            <Route exact path="/contact">
              <Contact />
            </Route>
            <Route exact path="/listen">
              <SpeechRecognitionComponent />
            </Route>
            <Route exact path="/faq">
              <FAQ />
              <Footer />
            </Route>
            <Route exact path="/support">
              <Support />
              <Footer />
            </Route>
            <Route exact path="/login">
              <NewLogin />
              <Footer />
            </Route>
            <Route exact path="/newlogin">
              <NewLogin />
              <Footer />
            </Route>
            <Route exact path="/confirm">
              <Confirm />
              <Footer />
            </Route>
            <Route exact path="/wregister">
              <WebAuthn />
              <Footer />
            </Route>
            <Route exact path="/security">
              <WebSecurity />
              <Footer />
            </Route>
            <Route exact path="/wlogin">
              <WebAuthnLogin />
              <Footer />
            </Route>
            <Route exact path="/fregister">
              <AuthFido />
              <Footer />
            </Route>
            <Route exact path="/mcqtest">
              <MCQTest />
              <Footer />
            </Route>
            <Route exact path="/newinvite">
              <NewInvite />
              <Footer />
            </Route>
            <Route exact path="/dashboard">
              <Dashboard />
              <Footer />
            </Route>
            <Route exact path="/newdashboard">
              <NewDashboard />
              <Footer />
            </Route>
            <Route exact path="/admindashboard">
              <AdminDashboard />
              <Footer />
            </Route>
            <Route exact path="/monidash">
              <MonitorDashboard />
              <Footer />
            </Route>
            <Route exact path="/newmonidash">
              <NewMonitorDashboard />
              <Footer />
            </Route>
            <Route exact path="/monigriddash">
              <MonitorGridDashboard />
              <Footer />
            </Route>
            <Route exact path="/livedash">
              <LiveDashboard />
              <Footer />
            </Route>
            <Route exact path="/livestudentdash">
              <LiveStudentDashboard />
              <Footer />
            </Route>
            <Route exact path="/completed">
              <ExamCompleted />
              <Footer />
            </Route>
            <Route exact path="/newcompleted">
              <NewExamCompleted />
              <Footer />
            </Route>
            <Route exact path="/testdash">
              <FlexiExamDashboard />
              <Footer />
            </Route>
            <Route exact path="/upcoming">
              <UpcomingExamDashboard />
              <Footer />
            </Route>
            <Route exact path="/flexi">
              <FlexiExamDashboard />
              <Footer />
            </Route>
            <Route exact path="/unscheduled">
              <UnscheduledExamDashboard />
              <Footer />
            </Route>
            <Route exact path="/allexamdash">
              <AdminExamDashboard />
              <Footer />
            </Route>
            <Route exact path="/pastexamdash">
              <PastExamDashboard />
              <Footer />
            </Route>
            <Route exact path="/newexamdash">
              <NewExamDashboard />
              <Footer />
            </Route>
            <Route exact path="/examcarddash">
              <ExamCardDashboard />
              <Footer />
            </Route>
            <Route exact path="/Interview">
              <Interview />
              <Footer />
            </Route>
            <Route exact path="/newtest">
              <NewTest />
              <Footer />
            </Route>
            <Route exact path="/startTest/:testId">
              i      <StartTest />
              <Footer />
            </Route>

            <Route exact path="/form">
              <Form />
              <Footer />
            </Route>
            <Route exact path="/viewexam">
              <ViewExam />
              <Footer />
            </Route>
            <Route exact path="/newform">
              <NewForm />
              <Footer />
            </Route>
            <Route exact path="/profile/">
              <Profile />
              <Footer />
            </Route>
            <Route exact path="/newprofile/">
              <NewProfile />
              <Footer />
            </Route>
            <Route exact path="/verifyhelp/">
              <VerifyHelp />
              <Footer />
            </Route>
            <Route exact path="/viewprofile/">
              <ViewProfile />
              <Footer />
            </Route>


            <Route exact path="/wait/">
              <Wait />
              <Footer />
            </Route>
            <Route exact path="/newwait/">
              <NewWait />
              <Footer />
            </Route>
            <Route exact path="/verify">
              <VerifyStudent />
              <Footer />
            </Route>
            <Route exact path="/reset">
              <ResetPassword />
              <Footer />
            </Route>
            <Route exact path="/newreset">
              <NewResetPassword />
              <Footer />
            </Route>
            <Route exact path="/privacy">
              <Privacy />
              <Footer />
            </Route>
            <Route exact path="/terms">
              <Terms />
              <Footer />
            </Route>
            <Route exact path="/success">
              <Menu />
              <Success />
              <Footer />
            </Route>
            <Route exact path="/submitjotform/:examId/:studentId">
              <SubmitJotForm />
              <Footer />
            </Route>
            <Route exact path="/cancelled">
              <Menu />
              <Cancel />
              <Footer />
            </Route>
            <Route exact path="/failure">
              <Menu />
              <Failure />
              <Footer />
            </Route>
            <Route exact path="/pricing">
              <Pricing />
            </Route>
            <Route exact path="/job">
              <Job />
              <Footer />
            </Route>
            <Route exact path="/howitworks">
              <HowItWorks />
              <Footer />
            </Route>
          </div>
        </Router>
      </div>
    </div>
  );
}

export default App;
