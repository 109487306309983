// // src/apiHelper.js

import { clearSessionStorage, getFromSessionStorage, saveMultipleToSessionStorage, saveToSessionStorage, SESSION_STORAGE_KEYS } from "./sessionStorage";

// import axios from 'axios';

// // Base URL for your API
// const API_BASE_URL = 'https://beta.monitorexam.com';

// // Create an Axios instance
// const apiClient = axios.create({
//   baseURL: API_BASE_URL,
//   headers: {
//     'Content-Type': 'application/json',
//   },
// });

// // Function to get access token from local storage
// const getAccessToken = () => localStorage.getItem('accessToken');

// // Function to set access and refresh tokens to local storage
// const setTokens = (accessToken, refreshToken) => {
//   localStorage.setItem('accessToken', accessToken);
//   localStorage.setItem('refreshToken', refreshToken);
// };

// // Function to handle token refreshing
// const refreshToken = async () => {
//   const refreshToken = localStorage.getItem('refreshToken');
  
//   if (!refreshToken) {
//     throw new Error('No refresh token available');
//   }

//   try {
//     const response = await axios.post(`${API_BASE_URL}/refresh-token`, { refreshToken });
//     const { accessToken, newRefreshToken } = response.data;

//     // Save new tokens
//     setTokens(accessToken, newRefreshToken);
//     return accessToken;
//   } catch (error) {
//     console.error('Token refresh failed:', error);
//     throw error;
//   }
// };

// // Add request interceptor to include the access token in headers
// apiClient.interceptors.request.use(
//   async (config) => {
//     const accessToken = getAccessToken();
//     if (accessToken) {
//       config.headers.Authorization = `Bearer ${accessToken}`;
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// // Add response interceptor to handle token expiration and refresh
// apiClient.interceptors.response.use(
//   (response) => response,
//   async (error) => {
//     const originalRequest = error.config;

//     // Check if the error is due to unauthorized status (token expired)
//     if (error.response && error.response.status === 401 && !originalRequest._retry) {
//       originalRequest._retry = true;
      
//       try {
//         const newAccessToken = await refreshToken();
//         apiClient.defaults.headers.common['Authorization'] = `Bearer ${newAccessToken}`;
//         return apiClient(originalRequest);
//       } catch (refreshError) {
//         return Promise.reject(refreshError);
//       }
//     }
    
//     return Promise.reject(error);
//   }
// );

// // Function to handle login and store tokens
// export const login = async (username, password) => {
//   try {
//     const response = await apiClient.post('/user-api/login', { username, password });
//     const { accessToken, refreshToken } = response.data;

//     // Save tokens
//     setTokens(accessToken, refreshToken);
//     return response.data;
//   } catch (error) {
//     console.error('Login failed:', error);
//     throw error;
//   }
// };

// // Helper functions for API methods
// export const apiGet = async (endpoint, params = {}) => {
//   try {
//     const response = await apiClient.get(endpoint, { params });
//     return response.data;
//   } catch (error) {
//     console.error('GET request failed:', error);
//     throw error;
//   }
// };

// export const apiPost = async (endpoint, data = {}) => {
//   try {
//     const response = await apiClient.post(endpoint, data);
//     return response.data;
//   } catch (error) {
//     console.error('POST request failed:', error);
//     throw error;
//   }
// };

// export const apiPut = async (endpoint, data = {}) => {
//   try {
//     const response = await apiClient.put(endpoint, data);
//     return response.data;
//   } catch (error) {
//     console.error('PUT request failed:', error);
//     throw error;
//   }
// };

// export const apiDelete = async (endpoint, params = {}) => {
//   try {
//     const response = await apiClient.delete(endpoint, { params });
//     return response.data;
//   } catch (error) {
//     console.error('DELETE request failed:', error);
//     throw error;
//   }
// };

// // Export the Axios instance for direct API requests if needed
// export default apiClient;



// src/apiHelper.js

// Base URL for your API
const API_BASE_URL = 'https://beta.monitorexam.com';

// Function to get access token from local storage
const getAccessToken = () => getFromSessionStorage(SESSION_STORAGE_KEYS.accessToken);

// Function to set access and refresh tokens to local storage
const setTokens = (accessToken, refreshToken) => {
  console.log(accessToken,'SAVVING THE ACCES TOKE LOGIN')
  saveMultipleToSessionStorage({
    [SESSION_STORAGE_KEYS.accessToken] :accessToken,
    [SESSION_STORAGE_KEYS.refreshToken]:refreshToken
  })
};

// Function to handle token refreshing
const refreshToken = async () => {
  const refreshToken = getFromSessionStorage(SESSION_STORAGE_KEYS.refreshToken);
  
  if (!refreshToken) {
    throw new Error('No refresh token available');
  }

  try {
    const response = await fetch(`${API_BASE_URL}/refresh-token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ refreshToken }),
    });

    if (!response.ok) {
      throw new Error('Token refresh failed');
    }

    const data = await response.json();
    const { authToken:accessToken='', refreshToken:refreshToken='' } = data;

    // Save new tokens
    setTokens(accessToken, refreshToken);
    return accessToken;
  } catch (error) {
    console.error('Token refresh failed:', error);
    throw error;
  }
};

// Function to handle login and store tokens
export const login = async (username, password) => {
  try {
    const response = await fetch(`${API_BASE_URL}/user-api/login/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ "email":username, "password":password }),
    });


    if (response.ok) {
      const data = await response.json();
    const { authToken:accessToken='', refreshToken:refreshToken='' } = data;

    // Save tokens
    setTokens(accessToken, refreshToken);
    return data;
    }else {
      const errorData = await response.json();
      throw errorData
    }

  } catch (error) {
    console.error('Login failed:', error);
    throw error;
  }
};
export const logout = async()=>{
  try {
    const accessToken = getAccessToken()
    // console.log(accessToken,'ACCESS TOKEN IS ---------')
    const response = await fetch(`${API_BASE_URL}/user-api/logout/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
      body: {},
    });

    if(response.ok) {
      sessionStorage.clear()
      localStorage.clear()  
      window.location.reload()
    }

   return response

  } catch (error) {
    console.error('Login failed:', error);
    throw error;
  }
}

// Helper functions for API methods using fetch
const apiFetch = async (endpoint, options = {}) => {
  const accessToken = getAccessToken();
  console.log(accessToken,'ACCESS TOKENIS ---------')
  
  if (accessToken) {
    options.headers = {
      ...options.headers,
      'Authorization': `Bearer ${accessToken}`,
    };
  }

  try {
    const response = await fetch(`${API_BASE_URL}${endpoint}`, options);

    if (response.status === 401) {
      // Token might be expired, try to refresh it
      const newAccessToken = await refreshToken();

      // Retry the request with the new token
      options.headers['Authorization'] = `Bearer ${newAccessToken}`;
      const retryResponse = await fetch(`${API_BASE_URL}${endpoint}`, options);

      if (!retryResponse.ok) {
        throw new Error('Request failed after token refresh');
      }

      return retryResponse.json();
    }

    if (!response.ok) {
      throw new Error('Request failed');
    }

    return response.json();
  } catch (error) {
    console.error('API request failed:', error);
    throw error;
  }
};

// GET request
export const apiGet = (endpoint, params = {}) => {
  const queryString = new URLSearchParams(params).toString();
  const url = queryString ? `${endpoint}?${queryString}` : endpoint;
  return apiFetch(url, { method: 'GET' });
};

// POST request
export const apiPost = (endpoint, data = {}) => {
  return apiFetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
};

// PUT request
export const apiPut = (endpoint, data = {}) => {
  return apiFetch(endpoint, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
};

// DELETE request
export const apiDelete = (endpoint, params = {}) => {
  const queryString = new URLSearchParams(params).toString();
  const url = queryString ? `${endpoint}?${queryString}` : endpoint;
  return apiFetch(url, { method: 'DELETE' });
};

