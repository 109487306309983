// sessionStorageUtils.js


export const SESSION_STORAGE_KEYS = Object.freeze({
    accessToken:'accessToken',
    refreshToken:'refreshToken',
    user:'user'

})

export const saveToSessionStorage = (key, value) => {
    try {
      sessionStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.error('Error saving to session storage:', error);
    }
  };
  

  export const getFromSessionStorage = (key) => {
    try {
      const value = sessionStorage.getItem(key);
      return value ? JSON.parse(value) : null;
    } catch (error) {
      console.error('Error retrieving from session storage:', error);
      return null;
    }
  };
  
 
  export const removeFromSessionStorage = (key) => {
    try {
      sessionStorage.removeItem(key);
    } catch (error) {
      console.error('Error removing from session storage:', error);
    }
  };
  
  /**
   * Clear all session storage.
   */
  export const clearSessionStorage = () => {
    try {
      sessionStorage.clear();
    } catch (error) {
      console.error('Error clearing session storage:', error);
    }
  };
  

  export const saveMultipleToSessionStorage = (items) => {
    if (typeof items !== 'object' || items === null) {
      console.error('Invalid input: Expected an object with key-value pairs.');
      return;
    }
  
    Object.keys(items).forEach((key) => {
      try {
        sessionStorage.setItem(key, JSON.stringify(items[key]));
      } catch (error) {
        console.error(`Error saving key "${key}" to session storage:`, error);
      }
    });
  };