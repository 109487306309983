import React, {Component} from 'react'
import Button from 'react-bootstrap/Button'
import ReactGA from 'react-ga';
import Http from 'axios'
import {Redirect} from 'react-router-dom'
class NewContact extends React.Component{
	constructor(){
		super();
		// Add your tracking ID created from https://analytics.google.com/analytics/web/#home/
		ReactGA.initialize('UA-53038418-2');
		// This just needs to be called once since we have no routes in this case.
		ReactGA.pageview(window.location.pathname);
		//cv=require('./opencv.js');
		this.state={name:"She",role:"Tutor",email:"she@email.com",mobile:"1111122222",status:true};
	}

	handleChange = (event)=>{
		let nam=event.target.name;
		let val=event.target.value;
		this.setState({[nam]:val});
	}
	handleSubmit = (event)=>{
		const x=new Date();
		event.preventDefault();
		        Http.post('contact-api/contact',{
		          "role":this.state.role,
		          "name":this.state.name,
		          "email":this.state.email,
		          "mobile":this.state.mobile,
		          "message1":this.state.message1,
			  "timezone":x.getTimezoneOffset(),
		        }, {
		          headers: {
		            "Content-Type": "application/json"
		          }
		        })
		      .then(res => {
                      const response = res.data;
                      this.setState({ user:response,status:false});
	              return <Redirect to="/" />
                       })
	}
  render() {
    return (
      <div>
          <header className="header" style={{top:"0px"}}>
            <div className="">
              <a href="/" className="logo"><img src="../images/monitor-exam-logo.png" alt="logo" width={180} /></a>
              <input className="menu-btn" type="checkbox" id="menu-btn" />
              <label className="menu-icon" htmlFor="menu-btn"><span className="navicon" /></label>
              <ul className="menu">
                <li><a href="\">Home</a></li>
		<li><a href="\about" >About Us</a></li>
                <li className="hiw"><a href="\howitworks" >How It Works</a></li>
                <li><a href="\faq">FAQ's</a></li>
                <li><a href="\contact">Contact</a></li>
                <li><a href="\newlogin" className="btn btn-lg btn-outline-primary header-btn login">Login</a></li>
                <li><a href="\newiregister" className="btn btn-lg btn-primary header-btn">Register</a></li>
              </ul>
            </div>
          </header>
        <meta charSet="UTF-8" />
        <title> Contact Us Form</title>
        <link rel="stylesheet" href="style.css" />
        {/* Fontawesome CDN Link */}
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/css/all.min.css" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <div className="container pt-100">
          <div className="content">
            <div className="left-side">
              <div className="address details">
                <i className="fas fa-map-marker-alt" />
                <div className="topic">Address</div>
                <div className="text-one"> Sector 52, Gurugram</div>
                <div className="text-two">Haryana:122003</div>
              </div>
              <div className="mobile details">
                <i className="fas fa-mobile-alt" />
                <div className="topic">Mobile</div>
                <div className="text-one">
		<p><a href="tel:=+917982151592" style={{color:"#6576ff"}}>+91 7982151592</a></p></div>
              </div>
              <div className="email details">
                <i className="fas fa-envelope" />
                <div className="topic">Email</div>
                <div className="text-one"> 
		<p><a href="mailto:care@monitorexam.com" style={{color:"#6576ff"}}>care@monitorexam.com</a></p> 
	        </div>
              </div>
            </div>
            <div className="right-side" style={{display:this.state.status?"block":"none"}}>
              <div className="topic-text">Get in touch with us!!</div>
              <p> Are you a School, Tutor, or Parent?</p>
		<form onSubmit={this.handleSubmit} >
                <div className="input-box">
		  <input type='text' placeholder='Enter your name' name='name' onChange={this.handleChange} required ></input>
                </div>
                <div className="input-box">
		  <input type='text' placeholder='Enter your email' name='email' onChange={this.handleChange}></input>
                </div>
                <div className="input-box message-box">
		  <textarea placeholder='Enter your message' name='message1' rows="5" onChange={this.handleChange} required  className="flex-item" style={{margin:'4px',width:'90%'}}></textarea>
                </div>
		<Button style={{backgroundColor:"#6576ff"}}  className="button" variant="primary" type='submit' placeholder='Register' size="lg" required>Contact Us</Button>
              </form>
            </div>
            <div className="right-side" style={{display:this.state.status?"none":"block"}}>
	    <h5> Thank you for contacting us!!!</h5>
	    <p> We will get back to you within one working day </p>
	    </div>
          </div>
        </div>
      </div>
    );
  }
}
export default NewContact
